import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Breadcrumb from '../components/breadcrumb'
import GenericPage from '../components/genericPage'

import content from '../json/method.json'

const MethodologyPage = ({location}) => {
  return (
    <Layout simpleHeader={true} url="/notre-methodologie">
      <SEO 
        title="4 catégories pour mesurer l'impact positif de grandes marques"
        description="Les sites de marque françaises sont-ils à la hauteur de leurs ambitions ? A travers 4 catégories, découvrez comment Big Youth les a évalués."
        path="/notre-methodologie" />

      <Breadcrumb url="/notre-methodologie" label="Notre méthodologie" type="method" />

      <GenericPage slug="method" content={content} />
    </Layout>
  )
  }

export default MethodologyPage

